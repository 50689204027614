import { Component, EventEmitter, Input, Output, OnChanges, OnDestroy } from '@angular/core';
import { Category } from '@core/models/category.model';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-category-bar',
  templateUrl: './category-bar.component.html',
  styleUrls: ['./category-bar.component.scss']
})

export class CategoryBarComponent implements OnChanges, OnDestroy {

  public selectedCategory: string;

  @Input()
  public activeCategoryId: number | string;

  @Input()
  public activeSubCategoryId: number | string;

  @Input()
  public categories: Category[] = [];

  @Input()
  public sidebar: boolean;

  @Input()
  public onlyCategory: boolean;

  @Input()
  public onlySubCategory: boolean;

  @Output()
  public categoryClick = new EventEmitter<Category>();

  public subCategories: Category[];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private router: Router) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.includes('dashboard')) {
            this.activeCategoryId = 0;
          }
        }
      });
    this.selectedCategory = 'Alle';
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnChanges(): void {
    if (this.activeCategoryId && this.categories) {
      const result = this.categories.find((cat) => cat.id === this.activeCategoryId);

      if (result) {
        this.selectedCategory = result.attributes.name;
        if (result.categories) {
          this.subCategories = result.categories;
        } else {
          this.subCategories = null;
        }
      }
    }
  }

  public selectCategory(category: Category): void {
    if (category && category.attributes.name === 'Home') {
      return;
    }

    this.selectedCategory = category.attributes.name;

    this.activeCategoryId = category.id;
    this.categoryClick.emit(category);

    if (category.categories) {
      this.subCategories = category.categories;
    } else {
      this.subCategories = null;
    }
  }

  public selectSubCategory(category: Category): void {
    this.activeSubCategoryId = category.id;
    this.categoryClick.emit(category);
  }
}
