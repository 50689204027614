import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  /**
   * Close emit
   *
   * @returns void
   */
  public closeClick(): void {
    this.close.emit();
  }
}
