export const lotStatuses = {
  OWN_LOT: 'own-lot',
  HIGHEST_BIDDER: 'highest-bidder',
  OWN_LOT_CLOSED: 'own-lot-closed',
  OWN_LOT_CLOSED_NO_BIDS: 'own-lot-closed-no-bids',
  OVERBID: 'overbid',
  ALREADY_AWARDED: 'already-awarded',
  WON: 'won',
  NOT_AUTHENTICATED: 'not-authenticated',
  CLOSED: 'closed',
  PAID: 'paid',
  DONATE: 'donate',
  PAID_BY_SOMEONE_ELSE: 'paid-someone-else'
};
