import { AfterContentInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { TemplateDirective } from '@shared/directives/template.directive';

@Component({
  selector: 'app-detail-field',
  templateUrl: './detail-field.component.html',
  styleUrls: ['./detail-field.component.scss']
})
export class DetailFieldComponent implements AfterContentInit {
  @Input()
  public label: string;
  @Input()
  public value: string;

  public labelTemplate: TemplateRef<string>;
  public valueTemplate: TemplateRef<string>;

  @ContentChildren(TemplateDirective)
  public templates: QueryList<any>;

  public ngAfterContentInit(): void {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'value':
          this.valueTemplate = item.template;
          break;

        case 'label':
          this.labelTemplate = item.template;
          break;
      }
    });
  }
}
