import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirstOpenGuard implements CanActivateChild {
  constructor(
  ) { }

  public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //const hasOpened = await this.storage.get('hasOpened');
    return true;
    // if (hasOpened) {
    //   return true;
    // } else {
    //   this.router.navigate(['/intro']);
    //   return false;
    // }
  }

}
