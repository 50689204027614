import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/internal/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor, OnDestroy {
  private isTokenRefreshed = new Subject<string>();
  private _isRefreshingToken = false;
  private _accessToken: string;
  private destroyed$ = new Subject();

  constructor(private authService: AuthService) {
    authService.getAccessTokenObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((token) => this.accessToken = token);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (this.isErrorNotAuthorizedType(err, req)) {
          if (this.isRefreshingToken) {
            return this.cacheRequestUntilTokenRefreshed(req, next);
          }

          this.isRefreshingToken = true;

          return this.authService.refreshToken()
            .pipe(
              map((res: any) => (res.access_token)),
              switchMap((token: any) => {
                this.accessToken = token;
                this.isRefreshingToken = false;
                this.isTokenRefreshed.next();

                const request = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${this.accessToken}`
                  }
                });

                return next.handle(request);
              })
            );
        }

        return throwError(err);
      })
    );
  }

  public cacheRequestUntilTokenRefreshed(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isTokenRefreshed
      .pipe(
        take(1),
        switchMap(() => {
          const request = req.clone({
            setHeaders: {
              Authorization: 'Bearer ' + this.accessToken
            }
          });

          return next.handle(request);
        })
      );
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;
  }

  get isRefreshingToken(): boolean {
    return this._isRefreshingToken;
  }

  set isRefreshingToken(value: boolean) {
    this._isRefreshingToken = value;
  }

  public isErrorNotAuthorizedType(err: HttpErrorResponse, req: HttpRequest<any>): boolean {
    return !req.url.endsWith('/oauth/token') &&
      !!err &&
      (err.status === 401) ||
      (err.hasOwnProperty('error') && err.error.hasOwnProperty('status') && err.error.status === 401);
  }
}
