import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-avatar',
  templateUrl: './skeleton-avatar.component.html',
  styleUrls: ['./skeleton-avatar.component.scss']
})
export class SkeletonAvatarComponent implements OnInit {
  @Input()
  public amount: number;

  public items: number[];

  constructor() {
  }

  public ngOnInit(): void {
    this.items = new Array<number>(this.amount || 0);
  }
}
