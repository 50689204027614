import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FirstOpenGuard } from '@core/guards/first-open.guard';
import { routeNames } from '@root/src/app/config';

const routes: Routes = [
  {
    path: routeNames.HOME,
    loadChildren: () => import('./features/pages.module').then(m => m.PagesModule)
  },
  {
    path: routeNames.AUTH.DEFAULT,
    canActivateChild: [FirstOpenGuard],
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./features/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: '**',
    redirectTo: routeNames.HOME
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
