import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { routeNames } from '@config/index';
import { User } from '@core/models/user.model';
import { ApiService } from '@core/services/api.service';
import { LoaderService } from '@core/services/loader.service';
import { StorageService } from '@core/services/storage.service';
import { ToastService } from '@core/services/toast.service';
import { UserService } from '@core/services/user.service';
import { environment } from '@environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { Facebook } from '@node_modules/@ionic-native/facebook/ngx';
import { GooglePlus } from '@node_modules/@ionic-native/google-plus/ngx';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from '@node_modules/rxjs';
import { fromPromise } from '@node_modules/rxjs/internal/observable/fromPromise';
import { catchError, mergeMap, switchMap, tap } from '@node_modules/rxjs/internal/operators';
import { PushNotificationsService } from '@core/services/push-notifications.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public routeNames = routeNames;
  public authState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private apiService: ApiService,
    private storageService: StorageService,
    private navController: NavController,
    private facebook: Facebook,
    private toastService: ToastService,
    private translateService: TranslateService,
    private google: GooglePlus,
    private userService: UserService,
    private platform: Platform,
    private loader: LoaderService,
    private pushNotificationsService: PushNotificationsService,
  ) {
    this.userService.userChanged$.subscribe(() => {
      this.setCurrentUser();
    });
  }

  public setLoggedState(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.storageService.getAccessToken().then((response) => {
        var userLanguage = 'nl';
        if (response) {
          this.authState.next(true);
          this.pushNotificationsService.askForPermission();
          this.setCurrentUser().then(() => {
            this.storageService.getUser().then((user) => {
              if (user.attributes.language) {
                userLanguage = user.attributes.language.toLowerCase();
              }
              this.translateService.setDefaultLang(userLanguage);
            });
            resolve(true);
          });
        } else {
          this.authState.next(false);
          this.translateService.setDefaultLang(userLanguage);
          resolve(true);
        }
      });
    });
  }

  public isAuthenticated(): boolean {
    return this.authState.value;
  }

  public getAccessToken(): Promise<string> {
    return this.storageService.getAccessToken();
  }

  public getAccessTokenObservable(): Observable<string> {
    return fromPromise(this.getAccessToken());
  }

  public login(data: any): Observable<any> {
    this.loader.show(this.translateService.instant('auth.loader-login'));
    const payload = {
      grant_type: 'password',
      client_id: 2,
      client_secret: 'pKFulXgHfhMz0CbI0grfipKXpMfgXf7wIhuQLLbV',
      ...data
    };

    return this.apiService.http.post(`${environment.defaultUrl}/oauth/token`, payload)
      .pipe(
        mergeMap(res => {
          this.loader.hide();
          return this.handleLoginSuccess(res);
        })
      );
  }

  public loginGoogleAPI(payload: any): Observable<any> {
    return this.apiService.http.post(`${environment.defaultUrl}/auth/social/google`, payload)
      .pipe(
        mergeMap((res) => {
          return this.handleLoginSuccess(res);
        })
      );
  }

  public loginFacebookAPI(payload: any): Observable<any> {
    return this.apiService.http.post(`${environment.defaultUrl}/auth/social/facebook`, payload)
      .pipe(
        mergeMap((res) => {
          console.warn('facebook response', res);
          return this.handleLoginSuccess(res);
        })
      );
  }

  public loginWithFacebook(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.facebook.getLoginStatus()
        .then(async (res) => {
          if (res.status === 'connected') {
            await this.loader.show(this.translateService.instant('auth.loader-facebook'));

            await this.loginFacebookAPI({ 'accessToken': res.authResponse.accessToken }).toPromise();
            observer.next(true);

            this.loader.hide();
          } else {
            this.facebook.login(['email'])
              .then(async (response) => {
                await this.loader.show(this.translateService.instant('auth.loader-facebook'));

                await this.loginFacebookAPI({ 'accessToken': response.authResponse.accessToken }).toPromise();
                observer.next(true);

                this.loader.hide();
              }, (err) => {
                this.handleErrorResponse();
                observer.next(true);
              });
          }
        })
        .catch(() => {
          this.handleErrorResponse();
          observer.next(true);
        });
    });
  }

  public loginWithGoogle(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.google.login({})
        .then(async (user) => {
          await this.loader.show(this.translateService.instant('auth.loader-google'));

          await this.loginGoogleAPI({ accessToken: user.accessToken }).toPromise();
          observer.next(true);

          this.loader.hide();
        })
        .catch((err) => {
          this.handleErrorResponse();
          observer.next(true);
        });
    });
  }

  public async logout(): Promise<void> {
    await this.loader.show(this.translateService.instant('auth.loader-logout'));

    let socialLogin = "";
    this.storageService.getUser().then((user) => {
      if (user.attributes["social-login"]) {
        socialLogin = user.attributes["social-login"];
      }
    });

    let requests: Promise<any>[] = [
      this.storageService.removeAccessData(),
      this.setLoggedState(),
    ];

    try {
      if (this.platform.is('cordova')) {

        let socialLoginArray = socialLogin.split(',');
        let socialmedias = [];

        socialLoginArray.forEach(async function (value) {

          if (value == "google") {
            socialmedias = [this.google.logout()];
          }
          if (value == "facebook") {
            await this.facebook.getLoginStatus()
              .then(async (res) => {
                if (res.status !== 'unknown') {
                  socialmedias.push(this.facebook.logout());
                }
              });
          }
        });

        requests = [...requests, ...socialmedias];
      }
    } catch (error) {
      console.log('social catch:', error);
    }

    Promise.all(requests).then(() => {
      this.loader.hide();
      this.translateService.setDefaultLang('nl');
      this.navController.navigateBack(this.routeNames.AUTH.DEFAULT);
    }).catch((err) => {
      console.log('promise catch:', err);
      this.loader.hide();
      this.handleErrorResponse();
      this.navController.navigateBack(this.routeNames.AUTH.DEFAULT);
    });
  }

  public refreshToken(): Observable<string> {
    return this.storageService.getRefreshTokenAsObservable()
      .pipe(
        switchMap((refresh_token: string) => {
          if (!refresh_token) {
            return throwError('Failed to find refreshToken');
          }

          const headers = new HttpHeaders({
            Accept: 'application/json'
          });

          return this.apiService.http.post(environment.defaultUrl + '/auth/refresh', { refresh_token }, { headers })
            .pipe(
              tap((res: any) => {
                this.saveAccessData(res.data);
              })
            );
        }),
        catchError(err => {
          this.logout();

          return throwError(err);
        })
      );
  }

  public resetPassword(email: string): Observable<any> {
    return this.apiService.http.post(environment.defaultUrl + '/password/email', { email }).pipe(
      catchError((err) => this.apiService.formatErrors(err))
    );
  }

  public createAccount(data: any): Observable<any> {
    return this.apiService.http.post(`${environment.defaultUrl}/api/oauth/register`, data);
  }

  private handleLoginSuccess(response: any): Observable<void> {
    return new Observable((observer) => {
      this.saveAccessData(response).then(() => {
        this.setLoggedState().then(() => {
          observer.next();
          observer.complete();
        });
      });
    });
  }

  private async setCurrentUser(): Promise<void> {
    const user: User = await this.userService.getCurrentUser().toPromise();
    //console.warn('set current user:', user);
    await this.storageService.setUser(user);
  }

  private handleErrorResponse(): void {
    this.toastService.toast(this.translateService.instant('general.error.title'), this.translateService.instant('general.error.message'));
  }

  /**
   * Calls StorageService to save data in localstorage or other storage
   * @param data response from auth endpoint when successfully logged in or refreshed token
   */
  private saveAccessData(data: object): Promise<any[]> {
    return this.storageService.saveAccessData(data);
  }
}
