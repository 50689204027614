import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { routeNames } from '@config/index';
import { Lot } from '@core/models/lot.model';
import { AuthService } from '@core/services/auth.service';
import { LotService } from '@core/services/lot.service';
import { SaveService } from '@core/services/save.service';
import { ToastService } from '@core/services/toast.service';
import { environment } from '@environments/environment';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ActionSheetController, Platform, IonSlides } from '@ionic/angular';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { Subject } from '@node_modules/rxjs';
import { debounceTime, takeUntil } from '@node_modules/rxjs/internal/operators';
import { ClipboardService } from 'ngx-clipboard'
import { PayService } from '@core/services/pay.service';
import { LoaderService } from '@core/services/loader.service';
import { Router, NavigationExtras } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { User } from '@core/models/user.model';

@Component({
  selector: 'app-lot-item',
  templateUrl: './lot-item.component.html',
  styleUrls: ['./lot-item.component.scss']
})
export class LotItemComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  public item: Lot;

  @Input()
  public hasRelations?: boolean;

  @Input()
  public itemIndex?: number;

  @Output()
  public clickItem: EventEmitter<Lot> = new EventEmitter<Lot>();

  @Output()
  public saveChanged: EventEmitter<void> = new EventEmitter<void>();

  public user: User;
  public saved: boolean;
  public routeNames = routeNames;
  public imageIndex: number;
  public isPaid: boolean;
  public isOwnLot: boolean;

  public broadcastAvailable: boolean;

  public clicked$: Subject<void> = new Subject<void>();

  @ViewChild('slider', { static: false }) public slider: IonSlides;

  private debouncer = new Subject();
  private destroy$ = new Subject();

  constructor(
    public lotService: LotService,
    public actionSheetController: ActionSheetController,
    public authService: AuthService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private socialSharing: SocialSharing,
    private clipboard: Clipboard,
    private saveService: SaveService,
    private platform: Platform,
    private clipboardService: ClipboardService,
    private payService: PayService,
    private loader: LoaderService,
    private router: Router,
    private storageService: StorageService
  ) {

    this.imageIndex = 0;
    this.isPaid = false;
    this.broadcastAvailable = true;
    this.isOwnLot = false;

    this.debouncer
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(async () => {
        if (this.saved) {
          await this.saveService.save(this.item.id).toPromise();
        } else {
          await this.saveService.delete(this.item.saves[0].id).toPromise();
        }

        await this.reloadItem();
        this.saveChanged.emit();
      });

    this.clicked$
      .pipe(debounceTime(25), takeUntil(this.destroy$))
      .subscribe(() => {
        this.clickItem.emit(this.item);
      });
  }

  public async ngOnInit(): Promise<void> {
    this.user = await this.storageService.getUser();
    if (!this.hasRelations && this.item.id) {
      this.reloadItem();
    }

    var currentDateTime = new Date();
    var broadcastDateTime = new Date(this.item.attributes["close-at"]);
    broadcastDateTime.setMinutes(broadcastDateTime.getMinutes() - 20);

    if (currentDateTime >= broadcastDateTime) {
      this.broadcastAvailable = true;
    }

    this.item.images.sort(function (a, b) {
      return b.id - a.id;
    });

    /* this.item.images.forEach(element => {
      element.attributes.loaded = false;
    }); */

    //console.log('images:', this.item.images);

    this.saved = this.item && this.item.saves && this.item.saves[0] && !this.item.saves[0].attributes['deleted-at'];

    this.payService.get({ include: ['status'], filters: { 'lot-id': [this.item.id] } }).toPromise().then((payments) => {
      if (payments && payments[0]) {
        this.isPaid = payments[0].statuses && payments[0].statuses.length > 0 && payments[0].statuses[0].id === '20';
      }
    }, (err) => this.loader.hide());

    this.isOwnLot = this.user && (this.item && this.item.users && this.item.users.length > 0) && (this.user.id === this.item.users[0].id);

  }

  ngAfterViewInit() {

  }

  public ngOnChanges(): void {
    if (this.item && this.item.bids && this.item.bids.length > 0) {
      const filteredBids = [];

      for (const bid of this.item.bids) {
        if (!filteredBids.find((bidItem) => bidItem.id === bid.id)) {
          filteredBids.push(bid);
        }
      }

      this.item.bids = filteredBids;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public previousSlide(event: any): void {
    event.stopPropagation();

    this.slider.getActiveIndex().then((index) => {
      if ((this.imageIndex - 1) >= 0) {
        this.slider.slidePrev();
        this.imageIndex = index - 1;
      } else {
        this.imageIndex = index;
      }
    });
  }

  public nextSlide(event: any): void {
    event.stopPropagation();

    this.slider.getActiveIndex().then((index) => {
      if ((this.imageIndex + 1) <= this.item.images.length) {
        this.slider.slideNext();
        this.imageIndex = index + 1;
      } else {
        this.imageIndex = index;
      }
    });
  }

  /**
   * Save the Lot
   *
   * @param event: any
   * @returns void
   */
  public onSaveClick(event: any): void {
    event.stopPropagation();

    if (!this.authService.isAuthenticated()) {
      return;
    }

    this.saved = !this.saved;
    this.debouncer.next();
  }

  /**
   * Opens the menu
   *
   * @param event: any
   * @returns Promise<void>
   */
  public async openMenu(event: any): Promise<void> {
    event.stopPropagation();

    const buttons: any[] = [
      {
        text: this.translateService.instant('lot.cancel.label'),
        role: 'cancel'
      },
      {
        text: this.translateService.instant('lot.copy-link.label'),
        handler: () => this.copyToClipboard()
      },
      {
        text: this.translateService.instant('lot.share.label'),
        handler: () => this.onShareClick()
      }
    ];

    const menu = await this.actionSheetController.create({ buttons });
    await menu.present();
  }

  /**
   * Reload the Lot
   *
   * @returns Promise<void>
   */
  private async reloadItem(): Promise<void> {
    this.item = await this.lotService.getSingle(this.item.id, { include: ['bids', 'images', 'user', 'saves', 'acceptedBid', 'highestBid'] }).toPromise();
    this.saved = this.item && this.item.saves && this.item.saves[0] && !this.item.saves[0].attributes['deleted-at'];
  }

  /**
   * Copy link to clipboard
   *
   * @return void
   */
  private copyToClipboard(): void {
    const link = `${environment.appUrl}/${routeNames.LOT}/${this.item.id}`;

    if (this.platform.is('cordova')) {
      this.clipboard.copy(link).then(() => {
        this.toastService.toast(
          '',
          this.translateService.instant('lot.copy-link.message')
        );
      }).catch((error) => {
        this.toastService.toast(
          this.translateService.instant('general.error.title'),
          this.translateService.instant('general.error.message')
        );
      });
    } else {
      this.clipboardService.copy(link);

      this.toastService.toast(
        '',
        this.translateService.instant('lot.copy-link.message')
      );
    }
  }

  /**
   * Share the Lot
   *
   * @param event: any
   * @returns void
   */
  public onShareClick(event?: any): void {
    this.socialSharing.share(
      `Samen Handelen | ${this.item.attributes.name}`,
      this.item.attributes.name,
      null,
      `${environment.appUrl}/${routeNames.LOT}/${this.item.id}`
    ).catch((error) => {
      console.log('Social Sharing Error:', error);
      this.toastService.toast(
        this.translateService.instant('general.error.title'),
        this.translateService.instant('general.error.message')
      );
    });
  }

  /**
   * Redirect to broadcast page
   *
   * @param event: any
   * @returns void
   */
  public onBroadcastClick(event?: any): void {
    if (event) {
      event.stopPropagation();
    }

    let navigationExtras: NavigationExtras = {
      queryParams: { 'isOwnLot': this.isOwnLot }
    };

    this.router.navigate(['/lots/' + this.item.id + '/broadcast'], navigationExtras);
    if (this.platform.is('cordova')) {
      this.router.navigate(['/lots/' + this.item.id + '/broadcast'], navigationExtras);
    } else {
      // Do something to share with native browser..
    }
  }
}
