import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiModel, RelationData } from '@core/models/base-api.model';
import { Bid } from '@core/models/bid.model';
import { DocumentApiModel } from '@core/models/document.model';
import { Image } from '@core/models/image.model';
import { Label } from '@core/models/label.model';
import { Lot } from '@core/models/lot.model';
import { DropdownOption } from '@core/models/sorting-dropdown-options.interface';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LoaderService } from '@core/services/loader.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LotService extends ApiService {
  public endpoint = '/lots';

  public bannerChanged$: Subject<void> = new Subject<void>();
  public lotsChanged$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public lotChanged$: Subject<void> = new Subject<void>();
  public addedImages$ = new BehaviorSubject<Image[]>([]);
  public addedDocuments$ = new BehaviorSubject<any[]>([]);

  private addedLot: BehaviorSubject<Lot> = new BehaviorSubject<Lot>({
    type: 'lots',
    attributes: null,
    relationships: null
  });

  private addedlotLabels: Label[];
  private addedImages: Image[];

  constructor(http: HttpClient, private loader: LoaderService, private translateService: TranslateService) {
    super(http);
  }

  public getAddedImages(): Image[] {
    return this.addedImages;
  }

  public getAddedImagesStream(): Observable<Image[]> {
    return this.addedImages$.asObservable();
  }

  public setAddedImages(images: Image[]): void {
    this.addedImages$.next(images);
    this.addedImages = images;
  }

  public getAddedDocuments(): Observable<Image[]> {
    return this.addedDocuments$.asObservable();
  }

  public setAddedDocuments(images: Image[]): void {
    this.addedDocuments$.next(images);
  }

  public getAddedLot(): Observable<Lot> {
    return this.addedLot.asObservable();
  }

  public setAddedLot(lot: Lot): void {
    this.addedLot.next(lot);
  }

  public getAddedLotLabels(): Label[] {
    return this.addedlotLabels;
  }

  public setAddedLotLabels(labels: Label[]): void {
    this.addedlotLabels = labels;
  }

  public setLabelsRelations(id: string): void {
    for (const label of this.addedlotLabels) {
      (label.relationships.lot.data as RelationData).id = id;
    }
  }

  public setImagesRelations(id: string): void {
    for (const image of this.addedImages) {
      (image.relationships.lot.data as RelationData).id = id;
    }
  }

  public getLotSortingOptions(): DropdownOption[] {
    return [
      {
        id: '1',
        label: 'Prijs laag naar hoog',
        direction: 'asc',
        sortField: 'sell-price'
      }, {
        id: '2',
        label: 'Prijs hoog naar laag',
        direction: 'desc',
        sortField: 'sell-price'
      }, {
        id: '3',
        label: 'Startdatum nieuwste eerst',
        direction: 'desc',
        sortField: 'open-at'
      }, {
        id: '4',
        label: 'Startdatum oudste eerst',
        direction: 'asc',
        sortField: 'open-at'
      }, {
        id: '5',
        label: 'Einddatum nieuwste eerst',
        direction: 'desc',
        sortField: 'close-at'
      }, {
        id: '6',
        label: 'Einddatum oudste eerst',
        direction: 'asc',
        sortField: 'close-at'
      }
    ];
  }

  public createLabelsForLot(body: BaseApiModel): Observable<BaseApiModel> {
    return this.http.post<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}/lot-labels`, { data: body }, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public createImagesForLot(body: BaseApiModel): Observable<Image> {
    return this.http.post<{ data: Image, included: any }>(`${environment.baseUrl}/images`, { data: body }, { headers: this.getHeaders() }).pipe(
      map((response: { data: Image, included: any }) => this.getModelWithNestedRelations<Image>(response)),
      catchError(this.formatErrors)
    );
  }

  public uploadDocuments(documents: DocumentApiModel[], lotId: number): Observable<BaseApiModel[]> {
    const requests = documents.map((document) => this.getUploadDocumentRequest(document, lotId));

    return forkJoin(requests);
  }

  public awardLot(lot: Lot, data?: {}): Observable<BaseApiModel> {
    const body = { data: {} };

    if (data) {
      body.data = data;
    }

    return this.http.post<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${lot.id}/acceptBid`, body, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public denyLot(lot: Lot, data?: {}): Observable<BaseApiModel> {
    const body = { data: {} };

    if (data) {
      body.data = data;
    }

    return this.http.post<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${lot.id}/denyBid`, body, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public reset(): void {
    this.addedLot = new BehaviorSubject<Lot>({
      type: 'lots',
      attributes: null,
      relationships: null
    });

    this.addedlotLabels = null;
    this.addedImages = null;
  }

  public likeLot(lotId: number, userId: number): Observable<any> {
    this.endpoint = '/likes';

    const postData = {
      type: 'likes',
      relationships: {
        lot: {
          data: {
            type: 'lots',
            id: lotId
          }
        },
        user: {
          data: {
            type: 'users',
            id: userId
          }
        }
      }
    };

    return this.post(postData);
  }

  public getUpcomingLots(userId: number): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves'],
      filters: { 'user-id': [userId], 'scope': ['non-active'] }
    }).pipe(map((lots: Lot[]) => {
      return lots.filter((lot) => {
        return new Date(lot.attributes['open-at']) > new Date();
      });
    }), tap(() => this.loader.hide()));
  }

  public getRunningLots(userId: number): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves'],
      filters: { 'user-id': [userId], 'scope': ['active'] }
    }).pipe(map((lots: Lot[]) => {
      return lots.filter((lot) => {
        return new Date(lot.attributes['open-at']) < new Date() && new Date(lot.attributes['close-at']) > new Date();
      });
    }), tap(() => this.loader.hide()));
  }

  public getExpiredLots(userId: number): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves'],
      filters: { 'user-id': [userId], 'scope': ['non-active'] }
    }).pipe(map((lots: Lot[]) => {
      return lots.filter((lot) => {
        return new Date(lot.attributes['close-at']) < new Date();
      });
    }), tap(() => this.loader.hide()));
  }

  public getLotsWithRunningBids(bidIds: number[]): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves'],
      filters: { 'scope': ['active'] }
    }).pipe(map((lots: Lot[]) => {
      return lots.filter((lot) => {
        return lot.bids && lot.bids.some((bid) => bidIds.includes(bid.id));
      });
    }), tap(() => this.loader.hide()));
  }

  public getSavedLots(): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves'],
      filters: { scope: ['saved'] }
    }).pipe(tap(() => this.loader.hide()));
  }

  public getLotsWithWonBids(bids: Bid[]): Observable<Lot[]> {
    this.loader.show(this.translateService.instant('auction.loader'));

    const filteredBids = bids.filter((bid) => !!bid.attributes['settled-at']);
    const bidIds = filteredBids.map((bid) => bid.id);

    return this.get({
      include: ['user', 'images', 'bids', 'auction', 'category', 'status', 'highestBid', 'acceptedBid', 'saves']
    }).pipe(map((lots: Lot[]) => {
      return lots.filter((lot) => {
        return lot.bids && lot.bids.some((bid) => bidIds.includes(bid.id));
      });
    }), tap(() => this.loader.hide()));
  }

  public getAcceptedBid(lotId: number): Observable<Bid> {
    return this.http.get<{ data: Bid }>(`${environment.baseUrl}${this.endpoint}/${lotId}/acceptedBid`)
      .pipe(map((response) => response.data));
  }

  public calculateSellingPrice(lot: Lot): number {
    if (lot) {
      if (lot.highestBid) {
        return lot.highestBid.attributes.amount || 0;
      } else if (lot.bids && lot.bids.length > 0) {
        let highest = 0;

        for (const bid of lot.bids) {
          if (Number(bid.attributes.amount) > highest) {
            highest = Number(bid.attributes.amount);
          }
        }
        return highest;
      } else if (!lot.attributes['lowest-price'] || lot.attributes['lowest-price'] == null) {
        return lot.attributes['sell-price'] || 0;
      } else {
        return lot.attributes['lowest-price'] || 0;
      }
    }

    return 0;
  }

  private getUploadDocumentRequest(document: any, lotId: number): Observable<DocumentApiModel> {
    const postData: DocumentApiModel = {
      type: 'documents',
      attributes: {
        document: document.dataURI,
        name: document.name
      },
      relationships: {
        lot: {
          data: {
            type: 'lots',
            id: lotId.toString()
          }
        }
      }
    };

    return this.http.post<{ data: DocumentApiModel, included: any }>(`${environment.baseUrl}/documents`, { data: postData }, { headers: this.getHeaders() }).pipe(
      map((response: { data: DocumentApiModel, included: any }) => this.getModelWithNestedRelations<DocumentApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public updateBroadcastId(lotId: string, broadcastId: string): Observable<any> {
    const patchBody = {
      data: {
        type: 'lots',
        id: lotId,
        attributes: {
          'broadcast-id': broadcastId
        }
      }
    };

    return this.http.patch(`${environment.baseUrl}${this.endpoint}/${lotId}`, patchBody, { headers: this.getHeaders() });
  }

  /* public getBroadcastData(broadcastId: string) {

    const httpOptions = this.getHeaders();
    httpOptions.set('Accept', 'application/vnd.bambuser.v1+json');
    httpOptions.set('Authorization', 'Bearer ' + environment.bambuserAPIKey);

    return this.http.get('https://api.bambuser.com/broadcasts/' + broadcastId, { headers: httpOptions }
    ).pipe(map((response) => response));
  } */
}
