import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Lot } from '@core/models/lot.model';

@Component({
  selector: 'app-lot-list',
  templateUrl: './lot-list.component.html',
  styleUrls: ['./lot-list.component.scss']
})
export class LotListComponent implements OnInit {
  @Input()
  public items: Lot[];

  @Input()
  public hasRelations?: boolean;

  @Input()
  public columnSize?: number;

  @Input()
  public showMoreButton: boolean;

  @Output()
  public itemClick: EventEmitter<Lot> = new EventEmitter<Lot>();

  @Output()
  public refresh: EventEmitter<void> = new EventEmitter<void>();

  public skeletonItems: number[];
  public limit = 10;

  constructor() {
    this.skeletonItems = new Array(10);
  }

  public ngOnInit(): void {
    if (!this.showMoreButton) {
      this.limit = this.items && this.items.length ? this.items.length : 200;
    }
  }

  /**
   * Load more items in view
   *
   * @returns void
   */
  public loadMoreItems(): void {
    if (this.items && this.items.length && this.items.length > this.limit) {
      if ((this.limit + 10) < this.items.length) {
        this.limit += 10;
      } else {
        this.limit = this.items.length;
      }
    }
  }
}
