import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { StorageService } from '@core/services/storage.service';
import { User } from '@core/models/user.model';
import { UserService } from '@core/services/user.service';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(
    private oneSignal: OneSignal,
    private storageService: StorageService,
    private userService: UserService
  ) { }

  /**
   * Init the push notifications
   *
   * @returns void
   */
  public init(): void {
    console.log('Push Notification Init');
    this.oneSignal.getPermissionSubscriptionState().then((res) => {
      console.log('res.permissionStatus.status:', res.permissionStatus.status);
      if (res.permissionStatus.state || res.permissionStatus.status) {
        this.connectOneSignal();
      }
    });
  }

  public askForPermission(): void {
    this.oneSignal.promptForPushNotificationsWithUserResponse().then((res) => {
      this.init();
    });
  }

  public connectOneSignal(): void {
    console.log('connectOneSignal');
    this.oneSignal.startInit(environment.oneSignalIOS, environment.oneSignalAndroid);

    //this.updateOneSignalIds();

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(data => {
      // do something when notification is received
      alert('handleNotificationReceived' + JSON.stringify(data));
    });

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // do something when a notification is opened
      alert('handleNotificationOpened' + JSON.stringify(data));
    });

    this.oneSignal.endInit();

    this.updateOneSignalIds();
    console.log('OneSignal Connected!!!');
  }

  public updateOneSignalIds(): void {

    console.log('updateOneSignalIds');

    // Patch the onesignal id to API
    this.oneSignal.getIds().then(async (identity) => {
      console.log('OneSignal identity:', identity);
      const user: User = await this.storageService.getUser();

      if (user) {
        this.userService.updateOnesignalId(user.id.toString(), identity.userId).subscribe(() => {
          // Nice, we updated onesignal ID
          console.log('Nice, we updated onesignal ID:', identity.userId);
        });
      }
    });
  }
}
