import { Injectable } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { ModalService } from '@shared/components/modal/modal.service';
import { modalType } from '@features/auth/enums/modal-type.enum';
import { Bid } from '@core/models/bid.model';
import { User } from '@core/models/user.model';
import { PayService } from '@core/services/pay.service';
import { Lot } from '@core/models/lot.model';
import { StorageService } from '@core/services/storage.service';
import { PaymentFailedComponent } from '@shared/modules/payment/components/payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from '@shared/modules/payment/components/payment-success/payment-success.component';
import { LotService } from '@core/services/lot.service';
import { BrowserService } from '@core/services/browser.service';

@Injectable()
export class PaymentService {
  constructor(
    private modalService: ModalService,
    private payService: PayService,
    private browser: BrowserService,
    private storageService: StorageService,
    private lotService: LotService
  ) { }

  public payDirectly$(user: User, lot: Lot): Observable<void> {
    return new Observable<void>(observer => {
      const paymentData = {
        type: 'payments',
        attributes: {
          redirect_url: 'scobiddo://'
        },
        relationships: {
          lot: {
            data: {
              type: 'lots',
              id: lot.id.toString()
            }
          },
          user: {
            data: {
              type: 'users',
              id: user.id
            }
          }
        }
      };

      this.payService.post(paymentData).subscribe((payment) => {
        if (payment && payment.attributes) {
          const paymentStorage = { ...payment, lot };
          this.storageService.setPayment(paymentStorage);

          this.browser.open(payment.attributes['payment-url'], false);

          observer.next();
        }
      });
    });
  }

  public pay$(user: User, bid: Bid): Observable<void> {
    return new Observable<void>(observer => {
      const paymentData = {
        type: 'payments',
        attributes: {
          redirect_url: 'scobiddo://'
        },
        relationships: {
          bid: {
            data: {
              type: 'bids',
              id: bid.id.toString()
            }
          },
          user: {
            data: {
              type: 'users',
              id: user.id
            }
          }
        }
      };

      this.payService.post(paymentData).subscribe((payment) => {
        if (payment && payment.attributes) {
          const paymentStorage = { ...payment, bid };
          this.storageService.setPayment(paymentStorage);

          this.browser.open(payment.attributes['payment-url'], false);

          observer.next();
        }
      });
    });
  }

  public showPaymentStatusModal(paid: boolean): Promise<any> {
    this.lotService.bannerChanged$.next();

    return new Promise(async (resolve) => {
      if (paid) {
        const modal = await this.modalService.show(PaymentSuccessComponent, null, modalType.full, {
          confirm: () => {
            this.lotService.lotChanged$.next();
            modal.dismiss();
            resolve();
          }
        });
      } else {
        const modal = await this.modalService.show(PaymentFailedComponent, null, modalType.full, {
          confirm: () => {
            this.lotService.lotChanged$.next();
            modal.dismiss();
            resolve();
          }
        });
      }
    });
  }
}
