import { Injectable } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Subject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  public dashboardToTop$: Subject<void> = new Subject<void>();

  constructor(private keyboard: Keyboard, private platform: Platform) {
    if (this.platform.is('cordova')) {
      this.keyboard.hideFormAccessoryBar(false);
      this.keyboard.disableScroll(false);
    }
  }
}
