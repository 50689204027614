import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss']
})
export class SkeletonListComponent implements OnInit {
  @Input()
  public amount: number;

  public items: number[];

  constructor() {
  }

  public ngOnInit(): void {
    this.items = new Array<number>(this.amount || 0);
  }

}
