import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StorageService } from '@core/services/storage.service';
import { EMPTY, Observable } from '@node_modules/rxjs';
import { fromPromise } from '@node_modules/rxjs/internal/observable/fromPromise';
import { switchMap } from '@node_modules/rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class SaveService extends ApiService {
  public endpoint = '/saves';

  constructor(private storageService: StorageService, http: HttpClient) {
    super(http);
  }

  public save(lotId: number): Observable<any> {
    return fromPromise(this.storageService.getUser()).pipe(
      switchMap((user) => {
        if (user) {
          const postData = {
            type: 'saves',
            relationships: {
              lot: {
                data: {
                  type: 'lots',
                  id: lotId
                }
              },
              user: {
                data: {
                  type: 'users',
                  id: user.id
                }
              }
            }
          };

          return this.post(postData);
        } else {
          return EMPTY;
        }
      })
    );
  }
}
