import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiModel, RelationData } from '@core/models/base-api.model';
import { environment } from '@environments/environment';
import { Observable, throwError } from '@node_modules/rxjs';
import { catchError, map } from '@node_modules/rxjs/internal/operators';

export interface QueryOptions {
  filters?: Record<string, Array<string | number>>;
  include?: string[];
  sort?: Record<string, 'asc' | 'desc'>;
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  public endpoint = '';

  constructor(public http: HttpClient) {
  }

  public formatErrors(error: any): Observable<never> {
    return throwError(error.error);
  }

  public get(options?: QueryOptions): Observable<any> {
    return this.http.get<{ data: BaseApiModel[], included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}${this.getOptionsQuery(options)}`, { headers: this.getHeaders() }).pipe(
      map((response) => this.mapArrayResponse<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public getSingle(id: number | string, options?: QueryOptions): Observable<any> {
    return this.http.get<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${id}${this.getOptionsQuery(options)}`, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public put(id: number | string, body: { [key: string]: any } = {}, options?: QueryOptions): Observable<any> {
    return this.http.put<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${id}${this.getOptionsQuery(options)}`, body, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public patch(id: number | string, body: { [key: string]: any } = {}, options?: QueryOptions): Observable<any> {
    return this.http.patch<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${id}${this.getOptionsQuery(options)}`, { data: body }, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public post(body: { [key: string]: any } = {}, options?: QueryOptions): Observable<any> {
    return this.http.post<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}${this.getOptionsQuery(options)}`, { data: body }, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<BaseApiModel>(response)),
      catchError(this.formatErrors)
    );
  }

  public delete(id: number | string, options?: QueryOptions): Observable<any> {
    return this.http.delete<{ data: BaseApiModel, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${id}${this.getOptionsQuery(options)}`, { headers: this.getHeaders() }).pipe(
      catchError(this.formatErrors)
    );
  }

  /**
   * Get headers object
   *
   * @returns HttpHeaders
   */
  public getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });

    return headers;
  }

  /**
   * Get Http parameters from object
   *
   * @param object: {}
   * @returns HttpParams
   */
  public getHttpParamsFromObject(object: {} = {}): HttpParams {
    return new HttpParams({ fromObject: object });
  }

  /**
   * Maps the response to an array which includes the relationships nested
   *
   * @param response: { data: T[], included: BaseApiModel[] }
   * @returns T[]
   */
  private mapArrayResponse<T extends BaseApiModel>(response: { data: T[], included: BaseApiModel[] }): T[] {
    return response.data.map((model) => {
      if (response.included) {
        for (const included of response.included) {
          for (const relation in model.relationships) {
            if (model.relationships.hasOwnProperty(relation) && model.relationships[relation].data
              && !Array.isArray(model.relationships[relation].data)) {
              const tempModel = model.relationships[relation].data as RelationData;

              if ((tempModel.type === included.type) && (tempModel.id === included.id)) {
                if (model.hasOwnProperty(included.type)) {
                  model[included.type].push(included);
                } else {
                  model[included.type] = [included];
                }
              }
            } else if (model.relationships.hasOwnProperty(relation) && model.relationships[relation].data
              && Array.isArray(model.relationships[relation].data)) {
              for (const item of model.relationships[relation].data as RelationData[]) {
                if (item.type === included.type && item.id === included.id) {
                  if (model.hasOwnProperty(included.type)) {
                    model[included.type].push(included);
                  } else {
                    model[included.type] = [included];
                  }
                }
              }
            }
          }
        }
      }

      return model;
    });
  }

  /**
   * Returns the given model with all the relations nested
   *
   * @param model: { data: T, included: BaseApiModel[] }
   * @returns T
   */
  public getModelWithNestedRelations<T extends BaseApiModel>(model: { data: T, included: BaseApiModel[] }): T {
    if (!model.included) {
      return model.data;
    }

    for (const relation of model.included) {
      const key = relation.type;

      if (model.data.hasOwnProperty(key)) {
        model.data[key].push(relation);
      } else {
        model.data[key] = [relation];
      }
    }

    return model.data;
  }

  /**
   * Returns the query options as a http query string
   *
   * @param options: QueryOptions
   * @returns string
   */
  public getOptionsQuery(options?: QueryOptions): string {
    if (!options) {
      return '';
    }

    const urlSegments = [];

    if (options.include) {
      urlSegments.push(`include=${options.include.join(',')}`);
    }

    if (options.filters) {
      for (const filterKey in options.filters) {
        if (options.filters.hasOwnProperty(filterKey)) {
          urlSegments.push(`filter[${filterKey}]=${options.filters[filterKey].join(',')}`);
        }
      }
    }

    if (options.sort) {
      const sortStrings = [];

      for (const sortKey in options.sort) {
        if (options.sort.hasOwnProperty(sortKey)) {
          sortStrings.push(`${options.sort[sortKey] === 'asc' ? '' : '-'}${sortKey}`);
        }
      }

      urlSegments.push(`sort=${sortStrings.join(',')}`);
    }

    return `?${urlSegments.join('&')}`;
  }

  public getPostBody(type: string, attributes: any, id: number | string = null): BaseApiModel {
    let postData: BaseApiModel = {
      type,
      attributes
    };

    if (id) {
      postData = { ...postData, id: id as number };
    }

    return postData;
  }
}
