import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
    private browser: InAppBrowser
  ) {
  }

  /**
   * Opens browser
   *
   * @param url: string
   * @param inapp: boolean
   *
   * @returns Promise<void>
   */
  public async open(url: string, inapp: boolean): Promise<void> {
    const options = { closebuttoncaption: 'Sluiten' };
    const type = inapp ? '_blank' : '_system';
    this.browser.create(url, type, options);
  }
}
