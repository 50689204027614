import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@core/core.module';
//import { CustomTranslateLoader } from '@core/translate/custom-translate-loader';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ThreeDeeTouch } from '@ionic-native/three-dee-touch/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@node_modules/@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskIonicModule } from '@node_modules/ngx-mask-ionic';
import { AppRoutingModule } from '@root/src/app/app-routing.module';
import { AppComponent } from '@root/src/app/app.component';
import { PaymentFailedComponent } from '@shared/modules/payment/components/payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from '@shared/modules/payment/components/payment-success/payment-success.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeNl);

@NgModule({
  declarations: [AppComponent, PaymentFailedComponent, PaymentSuccessComponent],
  entryComponents: [
    PaymentFailedComponent,
    PaymentSuccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    NgxMaskIonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        //useClass: CustomTranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    StatusBar,
    //SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    OneSignal,
    ThreeDeeTouch,
    InAppBrowser,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
