import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-close-fab-button',
  templateUrl: './close-fab-button.component.html',
  styleUrls: ['./close-fab-button.component.scss']
})
export class CloseFabButtonComponent {
  @Input()
  public iconClass = 'fal fa-times';
  @Output()
  public click = new EventEmitter<void>();
}
