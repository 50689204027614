import { Pipe, PipeTransform } from '@angular/core';
import { BaseApiModel } from '@core/models/base-api.model';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  public transform(list: BaseApiModel[], property: string, direction: string = 'desc'): BaseApiModel[] {
    return list && list.length ? this.sort(list, property, direction) : list;
  }

  /**
   * Sort the list
   *
   * @param list: BaseApiModel
   * @param property: string
   *
   * @returns BaseApiModel[]
   */
  private sort(list: BaseApiModel[], property: string, direction: string): BaseApiModel[] {
    return list.sort((a: BaseApiModel, b: BaseApiModel) => {
      if (direction === 'desc') {
        return (new Date(a.attributes[property]) < new Date(b.attributes[property])) ? 1 : -1;
      } else {
        return (new Date(a.attributes[property]) > new Date(b.attributes[property])) ? 1 : -1;
      }
    });
  }

}
