import { Pipe } from '@angular/core';

@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormat {
    transform(value: number,
        forDisplay: boolean = true,
        currencySign: string = '€ ',
        decimalLength: number = 2,
        chunkDelimiter: string = '.',
        decimalDelimiter: string = ',',
        chunkLength: number = 3): string {

        if (!forDisplay) {
            value /= 100;
        }

        if (forDisplay && !this.isFloat(value)) {
            value = Math.trunc(value);
        }

        let result = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
        let num = value.toFixed(Math.max(0, ~~decimalLength));

        return currencySign + (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), '$&' + chunkDelimiter);
    }

    public isFloat(n: number) {
        return Number(n) === n && n % 1 !== 0;
    }
}