import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'logo-button',
  styleUrls: ['logo-button.scss'],
  templateUrl: 'logo-button.html'
})
export class LogoButtonComponent {
  @Input() public logo: 'apple' | 'google' | 'facebook';
  @Output() public click: EventEmitter<any> = new EventEmitter<any>();
}
