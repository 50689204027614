import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';
import { flatMap } from '@node_modules/rxjs/operators';

@Injectable()
export class TokenAppendInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      return this.authService.getAccessTokenObservable().pipe(
        flatMap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });

          return next.handle(request);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
