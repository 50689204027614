import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SearchService } from '@core/services/search.service';
import { Subject, Observable } from 'rxjs';
import { Category } from '@core/models/category.model';
import { CategoryService } from '@core/services/category.service';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public categories$: Observable<Category[]>;
  public search: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private categoryService: CategoryService,
    private navController: NavController
  ) {
    this.getCategories();
    this.initForm();
  }

  public ngOnInit(): void {
    /*this.form.get('search').valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value: string) => {
        this.searchService.setSearchTerm(value);
      });*/
    this.route.queryParams.subscribe(params => {
      this.search = params['search'];
    });

    this.form.get('search').patchValue(this.search);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public categoryClick(category: Category): void {
    this.navController.navigateForward(`/lots?category=${category.id}`);
  }

  /**
   * Initialize the form
   *
   * @returns void
   */
  private initForm(): void {
    this.form = this.fb.group({
      search: [{ value: this.search, disabled: false }, Validators.required]
    });
  }

  private getCategories(): void {
    this.categories$ = this.categoryService.get({ filters: { 'scope': ['head'] } });
  }

  public searchAuction() {
    if (this.form.get('search').value.length < 1) {
      return;
    }
    this.searchService.setSearchTerm(this.form.get('search').value);
  }
}
