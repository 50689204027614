import { Component, Input } from '@angular/core';
import { NavParams } from '@node_modules/@ionic/angular';
import { ModalUtils } from '@shared/components/modal/modal.utils';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input()
  public component: any;

  @Input()
  public closable: boolean;

  @Input()
  public isComponent: boolean;

  constructor(private navParams: NavParams) {
  }

  public close(): void {
    ModalUtils.emitParam(this.navParams, 'dismiss');
  }
}
