import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    private loader: LoadingController
  ) {
  }

  /**
   * Gives a loader
   *
   * @param title: string
   *
   * @returns Promise<void>
   */
  public show(message: string): void {
    this.loader.getTop().then(async (currentLoader) => {
      if (!currentLoader) {
        const loading = await this.loader.create({
          message
        });

        await loading.present();
      }
    });

    setTimeout(() => this.hide(), 4000);
  }

  public hide(): void {
    this.loader.getTop().then((currentLoader) => {
      if (currentLoader) {
        currentLoader.dismiss();
      }
    });
  }
}
