import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})

// Forked from https://github.com/hamed-musallam/ionic3-pincode-input.git
export class PhoneNumberComponent implements OnChanges, AfterViewInit {
  @Input()
  public isHidden = false;

  @Input()
  public codeSize = 4;

  @Output()
  public complete = new EventEmitter<string>();

  public pinCodeArray: any[];
  public pinCodeFormGroup: FormGroup;

  constructor() {
    this.initiateBuilder();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const stylebody = document.body.style;

    if (changes.isHidden !== undefined) {
      if (changes.isHidden.currentValue === false) {
        stylebody.setProperty('--charShape', 'none');
      } else {
        stylebody.setProperty('--charShape', 'disc');
      }
    }

    this.initiateBuilder();

  }

  public ngAfterViewInit(): void {
    const input: HTMLElement = document.querySelectorAll('.pinCodeInput').item(0) as HTMLElement;
    input.focus();
  }

  public onKeyUp($event: any, item: any, index: any): void {
    let v_index;
    const reg = new RegExp('[0-9]');

    if ($event.key === 'Backspace') {
      if (index === 0) {
        v_index = 0;
      } else {
        v_index = index - 1;
        this.pinCodeFormGroup.get('codeFiled' + index).disable();
      }
    } else {
      if (reg.test($event.target.value)) {
        if (index === this.codeSize - 1) {
          v_index = this.codeSize - 1;
        } else {
          v_index = index + 1;
          this.pinCodeFormGroup.get('codeFiled' + v_index).enable();
        }
      }
    }

    const input: HTMLElement = document.querySelectorAll('.pinCodeInput').item(v_index) as HTMLElement;

    input.focus();

    if (index === this.codeSize - 1 && $event.key !== 'Backspace') {
      let pinCodeValue = '';

      Object.keys(this.pinCodeFormGroup.value).forEach((key) => {
        pinCodeValue += this.pinCodeFormGroup.value[key];
      });

      if (this.pinCodeFormGroup.valid && $event.key === 'Enter') {
        this.complete.emit(pinCodeValue);
      }
    }
  }

  public onKeyDown($event: any): boolean {
    if ($event.key !== 'Backspace') {
      if ($event.target.value.length === 1) {
        return false;
      }
    }
  }

  private initiateBuilder(): void {
    this.pinCodeFormGroup = new FormGroup({});

    for (let i = 0; i < this.codeSize; i++) {
      const formController: FormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.pinCodeFormGroup.addControl('codeFiled' + i, formController);
    }

    const v_pinCodeArray: any[] = [];
    Object.keys(this.pinCodeFormGroup.value).forEach((key) => {
      v_pinCodeArray.push(key);
    });

    this.pinCodeArray = v_pinCodeArray;
    this.pinCodeFormGroup.get('codeFiled0').enable();
  }
}
