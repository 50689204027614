import { Injectable } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { fromPromise } from '@node_modules/rxjs/internal/observable/fromPromise';
import { Storage } from '@node_modules/@ionic/storage';
import { User } from '@core/models/user.model';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private storage: Storage) {
  }

  public async getAccessToken(): Promise<string> {
    return this.storage.get('access_token');
  }

  public getRefreshToken(): Promise<string> {
    return this.storage.get('refresh_token');
  }

  public getRefreshTokenAsObservable(): Observable<string> {
    return fromPromise(this.getRefreshToken());
  }

  public getUser(): Promise<User> {
    return this.storage.get('user');
  }

  public saveAccessData(data: any): Promise<any[]> {
    return Promise.all([
      this.setAccessToken(data.access_token),
      this.setRefreshToken(data.refresh_token)
    ]);
  }

  public removeAccessData(): Promise<any[]> {
    return Promise.all([
      this.storage.remove('access_token'),
      this.storage.remove('refresh_token'),
      this.storage.remove('user')
    ]);
  }

  private setAccessToken(token: string): void {
    this.storage.set('access_token', token);
  }

  private setRefreshToken(token: string): void {
    this.storage.set('refresh_token', token);
  }

  public setUser(user: User): Promise<void> {
    return this.storage.set('user', user);
  }

  public setPayment(payment: any): Promise<void> {
    return this.storage.set('payment', payment);
  }

  public removePayment(): Promise<void> {
    return this.storage.remove('payment');
  }

  public getPaymentPending(): Promise<any> {
    return this.storage.get('payment');
  }
}
