import { Injectable } from '@angular/core';
import { ModalController } from '@node_modules/@ionic/angular';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SharedModule } from '@shared/shared.module';
import { ModalUtils } from '@shared/components/modal/modal.utils';
import { modalType } from '@features/auth/enums/modal-type.enum';

@Injectable({
  providedIn: SharedModule
})
export class ModalService {

  constructor(private modalController: ModalController) {
  }

  public async show(component: any, cssClass: string, type: modalType = modalType.actions, props?: any): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      component: ModalComponent,
      backdropDismiss: type !== modalType.closable,
      swipeToClose: true,
      cssClass: [ModalUtils.getClassByType(type), cssClass],
      componentProps: {
        ...props,
        component,
        closable: type === modalType.closable,
        isComponent: typeof component === 'function'
      },
    });

    await modal.present();

    return modal;
  }
}
