import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DetailFieldComponent } from '@features/lot/components/detail-field/detail-field.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { IonicModule } from '@ionic/angular';
import { WebView } from '@node_modules/@ionic-native/ionic-webview/ngx';
import { TranslateModule } from '@node_modules/@ngx-translate/core';
import { NgxMaskIonicModule } from '@node_modules/ngx-mask-ionic';
import { CategoryBarComponent } from '@shared/components/category-bar/category-bar.component';
import { CloseFabButtonComponent } from '@shared/components/close-fab-button/close-fab-button.component';
import { CountdownWrapperComponent } from '@shared/components/countdown-wrapper/countdown-wrapper.component';
import { DetailPageLayoutComponent } from '@shared/components/detail-page-layout/detail-page-layout.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { LogoButtonComponent } from '@shared/components/logo-button/logo-button.component';
import { LotItemComponent } from '@shared/components/lot/lot-item/lot-item.component';
import { LotListComponent } from '@shared/components/lot/lot-list/lot-list.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SkeletonAvatarComponent } from '@shared/components/skeleton/skeleton-avatar/skeleton-avatar.component';
import { SkeletonListComponent } from '@shared/components/skeleton/skeleton-list/skeleton-list.component';
import { TabbarComponent } from '@shared/components/tabbar/tabbar.component';
import { TemplateDirective } from '@shared/directives/template.directive';
import { FormValidationMessageComponent } from '@shared/components/form-validation-message/form-validation-message.component';
import { CountdownModule } from 'ngx-countdown';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { ThumbnailPipe } from '@shared/pipes/thumbnail.pipe';
import { CurrencyFormat } from '@shared/pipes/currency-format.pipe';
import { OrderByDatePipe } from '@shared/pipes/order-by-date.pipe';
import { PhoneNumberComponent } from '@shared/components/phone-number/phone-number.component';
import { StatusBannerComponent } from '@shared/components/status-banner/status-banner.component';
import { NotFoundComponent } from '@shared/components/not-found/not-found.component';
import { NotFoundwrapperComponent } from '@shared/components/not-found-wrapper/not-found-wrapper.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BrandsBannerComponent } from '@shared/components/brands-banner/brands-banner.component';
import { DesktopFooterComponent } from '@shared/components/desktop-footer/desktop-footer.component';
import { TextAvatarComponent } from './components/text-avatar/text-avatar.component';

@NgModule({
  declarations: [
    LogoButtonComponent,
    TabbarComponent,
    HeaderComponent,
    LotListComponent,
    LotItemComponent,
    SkeletonAvatarComponent,
    SkeletonListComponent,
    TemplateDirective,
    CategoryBarComponent,
    CloseFabButtonComponent,
    SafeUrlPipe,
    ThumbnailPipe,
    CurrencyFormat,
    CategoryBarComponent,
    CountdownWrapperComponent,
    ModalComponent,
    FormValidationMessageComponent,
    DetailPageLayoutComponent,
    OrderByDatePipe,
    PhoneNumberComponent,
    StatusBannerComponent,
    NotFoundComponent,
    NotFoundwrapperComponent,
    DetailFieldComponent,
    BrandsBannerComponent,
    DesktopFooterComponent,
    TextAvatarComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
    CountdownModule,
    NgxMaskIonicModule,
    ClipboardModule,
  ],
  exports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LogoButtonComponent,
    TabbarComponent,
    HeaderComponent,
    LotListComponent,
    LotItemComponent,
    SkeletonAvatarComponent,
    SkeletonListComponent,
    LogoButtonComponent,
    TemplateDirective,
    HttpClientModule,
    CategoryBarComponent,
    CloseFabButtonComponent,
    SafeUrlPipe,
    ThumbnailPipe,
    CurrencyFormat,
    OrderByDatePipe,
    CountdownWrapperComponent,
    TranslateModule,
    CountdownModule,
    FormValidationMessageComponent,
    DetailPageLayoutComponent,
    ModalComponent,
    PhoneNumberComponent,
    StatusBannerComponent,
    NotFoundComponent,
    NotFoundwrapperComponent,
    DetailFieldComponent,
    NgxMaskIonicModule,
    ClipboardModule,
    BrandsBannerComponent,
    DesktopFooterComponent,
    TextAvatarComponent
  ],
  providers: [
    WebView,
    SocialSharing
  ],
  entryComponents: [
    ModalComponent
  ]
})
export class SharedModule {

}
