import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { WebView } from '@node_modules/@ionic-native/ionic-webview/ngx';
import { LibraryItem, PhotoLibrary } from '@node_modules/@ionic-native/photo-library/ngx';
import { Observable } from '@node_modules/rxjs';
import { fromPromise } from '@node_modules/rxjs/internal/observable/fromPromise';
import { map } from '@node_modules/rxjs/internal/operators';

@Pipe({
  name: 'thumbnail'
})
export class ThumbnailPipe implements PipeTransform {

  constructor(private photoLibrary: PhotoLibrary, private sanitizer: DomSanitizer, private webView: WebView) {
  }

  public transform(value: LibraryItem): Observable<SafeUrl> {
    return fromPromise(this.photoLibrary.getThumbnail(value)).pipe(map((blob) => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.webView.convertFileSrc(URL.createObjectURL(blob)));
    }));
  }

}
