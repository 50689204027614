import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found-wrapper',
  templateUrl: './not-found-wrapper.component.html'
})
export class NotFoundwrapperComponent {
  constructor(private router: Router) { }

  public close(): void {
    this.router.navigateByUrl('/');
  }
}
