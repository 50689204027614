import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController
  ) {
  }

  /**
   * Gives a toast
   *
   * @param title: string
   * @param message: string
   *
   * @returns Promise<void>
   */
  public async toast(title: string = '', message: string): Promise<void> {
    const toast = await this.toastController.create({
      color: 'dark',
      position: 'bottom',
      duration: 2500,
      header: title,
      message
    });

    await toast.present();
  }
}
