import { Injectable } from '@angular/core';
import { Auction } from '@core/models/auction.model';
import { BaseApiModel } from '@core/models/base-api.model';
import { User } from '@core/models/user.model';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {
  public endpoint = '/users';

  public userChanged$: Subject<void> = new Subject<void>();
  public currentCreatingUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  public get currentCreatingUser(): Observable<User> {
    return this.currentCreatingUser$.asObservable();
  }

  public setCurrentCreatingUser(user: User): void {
    this.currentCreatingUser$.next(user);
  }

  /**
   * Get current authenticated User
   *
   * @returns Observable<User>
   */
  public getCurrentUser(): Observable<User> {
    return this.http.get<{ data: User, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/me`, { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<User>(response)),
      catchError(this.formatErrors)
    );
  }

  public getUserAuctions(id: number): Observable<any> {
    return this.http.get<{ data: Auction[] }>(`${environment.baseUrl}${this.endpoint}/${id}/auctions`).pipe(map((response) => response.data), catchError(() => of([])));
  }

  public submitImage(data: any, userId: string): Observable<any> {
    return this.http.patch<{ data: User, included: BaseApiModel[] }>(`${environment.baseUrl}${this.endpoint}/${userId}`, this.getPostBody('users', data, userId), { headers: this.getHeaders() }).pipe(
      map((response) => this.getModelWithNestedRelations<User>(response)),
      catchError(this.formatErrors)
    );
  }

  public updateOnesignalId(userId: string, oneSignalId: string): Observable<any> {
    const patchBody = {
      data: {
        type: 'users',
        id: userId,
        attributes: {
          'onesignal-id': oneSignalId
        }
      }
    };

    return this.http.patch(`${environment.baseUrl}${this.endpoint}/${userId}`, patchBody, { headers: this.getHeaders() });
  }
}
