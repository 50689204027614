import { NavParams } from '@node_modules/@ionic/angular';
import { modalType } from '@features/auth/enums/modal-type.enum';

export class ModalUtils {
  public static emitParam(navParams: NavParams, param: string, data?: any): void {
    if (navParams && navParams.get(param)) {
      const callback = navParams.get(param);

      callback(data);
    }
  }

  public static getClassByType(type: modalType): string {
    switch (type) {
      case modalType.closable:
      case modalType.actions:
        return 'actions-modal';
      case modalType.full:
        return 'full-modal';
      default:
        return '';
    }
  }
}
