/**
 * BootHelper - helper usually designed to support app.module.ts setup with auxiliary needs
 * e.g. callback methods as properties:
 * // boot-helper.ts
 * class BootHelper{
 *   public static onAuthError():void {
 *     // actual implementation
 *   }
 * }
 *
 * // app.module.ts
 * @NgModule({
 *   ...
 *   onAuthError: BootHelper.onAuthError,
 *   ....
 * })
 */
export class BootHelper {
  public static throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
    if (parentModule) {
      throw new Error(`${moduleName} has already been loaded. Import ${moduleName} modules in the AppModule only.`);
    }
  }
}
