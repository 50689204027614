import { Component, Input, OnChanges } from '@angular/core';
import { Lot } from '@core/models/lot.model';
import * as moment from 'moment';
import { Bid } from '@core/models/bid.model';

@Component({
  selector: 'app-countdown-wrapper',
  templateUrl: './countdown-wrapper.component.html',
  styleUrls: ['./countdown-wrapper.component.scss']
})
export class CountdownWrapperComponent implements OnChanges {
  @Input()
  public lot: Lot;

  @Input()
  public bid?: Bid;

  @Input()
  public isBid?: boolean;

  @Input()
  public isPaid?: boolean;

  public ngOnChanges(): void {
    this.calculateTime();
  }

  private calculateTime(): void {
    if (!this.lot.attributes || (this.lot.attributes && !this.lot.attributes['close-at'])) {
      this.lot.seconds = 0;

      return;
    }

    const expiry = moment(new Date(this.lot.attributes['close-at']));
    const now = moment(new Date());
    const diffInDays = moment.duration(expiry.valueOf() - now.valueOf()).subtract({ hours: 1 }).asDays();
    const diffInSeconds = moment.duration(expiry.valueOf() - now.valueOf()).subtract({ hours: 1 }).asSeconds();

    if (diffInDays >= 1) {
      this.lot.days = Math.floor(diffInDays);
    } else if (Math.ceil(diffInDays) <= 0) {
      this.lot.seconds = -1;
    } else if (this.isBid && ((diffInSeconds / 60) - 1) >= 1) {
      const diffInHours = (diffInSeconds / 60) / 60;
      const diffInMinutes = (diffInSeconds / 60) % 60;

      this.lot.hours = Math.floor(diffInHours);
      this.lot.minutes = Math.floor(diffInMinutes);
      this.lot.seconds = Math.floor(diffInSeconds % 60);
    } else {
      this.lot.seconds = Math.floor(diffInSeconds);
    }

    // TODO: Refactor to more sustainable solution
    if (this.isBid) {
      setTimeout(() => this.calculateTime(), 1000);
    }
  }
}
