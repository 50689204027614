import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
  }

  /**
   * Set the searchTerm Observable
   *
   * @param term: string
   * @returns void
   */
  public setSearchTerm(term: string): void {
    this.searchTerm$.next(term);
  }

  public getSearchTerm(): Observable<string> {
    return this.searchTerm$.asObservable();
  }
}
