import { Component } from '@angular/core';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { AuthService } from '@core/services/auth.service';
import { PushNotificationsService } from '@core/services/push-notifications.service';
import { StorageService } from '@core/services/storage.service';
import { PayService } from '@core/services/pay.service';
import { NavController } from '@ionic/angular';
import { PaymentService } from '@shared/modules/payment/payment.service';
import { ThreeDeeTouch, ThreeDeeTouchQuickAction } from '@ionic-native/three-dee-touch/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-root',
  template: `
    <ion-app>
      <ion-router-outlet></ion-router-outlet>
    </ion-app>
  `,
  providers: [PaymentService, InAppBrowser]
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private authService: AuthService,
    private pushNotificationsService: PushNotificationsService,
    private storageService: StorageService,
    private payService: PayService,
    private navController: NavController,
    private paymentService: PaymentService,
    private threeDeeTouch: ThreeDeeTouch,
  ) {
    this.initializeApp();
  }

  public initializeApp(): void {
    this.platform.ready().then(() => {
      this.authService.setLoggedState();

      if (this.platform.is('cordova')) {
        //this.statusBar.styleDefault();
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#0090e3');
        this.statusBar.show();
        this.pushNotificationsService.init();
        this.init3DTouchOptions();
        this.updateOneSignal();

        this.checkForPaymentPending();

        this.platform.resume.subscribe(() => {
          this.checkForPaymentPending();
          this.pushNotificationsService.init();
        });

        this.pushNotificationsService.connectOneSignal();
      }
    });
  }

  private init3DTouchOptions(): void {
    this.threeDeeTouch.isAvailable().then((available) => {
      if (available) {
        const actions: ThreeDeeTouchQuickAction[] = [
          {
            type: 'add',
            title: 'Veiling maken',
            iconType: 'Compose'
          },
          {
            type: 'favorites',
            title: 'Opgeslagen biedingen',
            iconType: 'Love'
          }
        ];

        this.threeDeeTouch.configureQuickActions(actions);

        this.threeDeeTouch.onHomeIconPressed()
          .subscribe((payload) => {
            switch (payload.type) {
              case 'add':
                this.navController.navigateForward('/lots/add', { animated: false });
                break;
              case 'favorites':
                this.navController.navigateForward('/profile/lots/saved-bids', { animated: false })
                break;
              default:
                break;
            }
          });
      }
    });
  }

  private async checkForPaymentPending(): Promise<void> {
    const payment = await this.storageService.getPaymentPending();

    if (payment && payment !== '') {
      const paymentWithStatus = await this.payService.getSingle(payment.id, { include: ['status'] }).toPromise();
      const paid: boolean = paymentWithStatus && paymentWithStatus.statuses && paymentWithStatus.statuses.length > 0 && paymentWithStatus.statuses[0].id === '20';

      this.navController.navigateForward(`/lots/${payment.bid ? payment.bid.lots[0].id : payment.lot.id}`, { animated: false }).then(() => {
        this.paymentService.showPaymentStatusModal(paid);
      });

      await this.storageService.removePayment();
    }

    return;
  }

  private updateOneSignal(): void {
    this.authService.authState.subscribe((state) => {
      this.pushNotificationsService.updateOneSignalIds();
    });
  }

}
