import { Component, Input } from '@angular/core';
import { lotStatuses } from '@config/index';
import { Lot } from '@core/models/lot.model';

@Component({
  selector: 'app-status-banner',
  templateUrl: './status-banner.component.html',
  styleUrls: ['./status-banner.component.scss'],
})
export class StatusBannerComponent {
  @Input()
  public message: string;

  @Input()
  public lot: Lot;

  public lotStatuses = lotStatuses;
}
