import { Component, Input, OnInit } from '@angular/core';
import { User } from '@core/models/user.model';

@Component({
  selector: 'app-text-avatar',
  templateUrl: './text-avatar.component.html',
  styleUrls: ['./text-avatar.component.scss'],
})
export class TextAvatarComponent implements OnInit {

  @Input()
  public name: string;

  @Input()
  public user: User;

  constructor() { }

  ngOnInit() {
    if (this.user) {
      if (this.user.attributes["first-name"]) {
        this.name = this.user.attributes["first-name"].charAt(0);
      }
      if (this.user.attributes["last-name"]) {
        this.name += this.user.attributes["last-name"].charAt(0);
      }
      this.name = this.name.toUpperCase().trim();
    }
  }

}
