export const routeNames = {
  HOME: '',
  AUTH: {
    DEFAULT: 'auth',
    LOGIN: 'login',
    RESET_PASSWORD: 'reset-password',
    CONFIRM_RESET_PASSWORD: 'confirm-reset-password',
    RESET_PASSWORD_SUCCESS: 'reset-password-success',
    ACCOUNT: 'account',
    ACCOUNT_CREATED: 'account-created',
    ACCOUNT_ADDRESS: 'account-address',
    ACCOUNT_CONFIRM_POLICY: 'account-confirm-policy',
    ACCOUNT_NOTIFICATIONS: 'account-notifications',
    ACCOUNT_CONFIRM_PHONE_NUMBER: 'account-confirm-phone-number',
    BUSINESS_ACCOUNT_PLACE: 'business-account-place',
    BUSINESS_ACCOUNT_PHOTO: 'business-account-photo',
    BUSINESS_ACCOUNT_CREATED: 'business-account-created'
  },
  DASHBOARD: 'dashboard',
  MAIN: 'main',
  AUCTION: 'auction',
  LOT: 'lots',
  USER: 'user',
  CATEGORIES: 'categories',
  NOTIFICATIONS: 'notifications',
  ADD: 'add',
  EDIT: 'edit',
  BROADCAST: 'broadcast',
  PROFILE: {
    DEFAULT: 'profile',
    LOTS: 'lots',
    ADDRESS: 'address',
    CONTACT: 'contact',
    PREFERENCES: 'preferences',
    TYPES: {
      UPCOMING_LOTS: 'upcoming-lots',
      RUNNING_LOTS: 'running-lots',
      PREVIOUS_LOTS: 'previous-lots',
      RUNNING_BIDS: 'running-bids',
      WON_BIDS: 'won-bids',
      LOST_BIDS: 'lost-bids',
      SAVED_BIDS: 'saved-bids'
    }
  }
};
