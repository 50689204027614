import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Image } from '@core/models/image.model';

@Component({
  selector: 'app-detail-page-layout',
  templateUrl: './detail-page-layout.component.html',
  styleUrls: ['./detail-page-layout.component.scss']
})
export class DetailPageLayoutComponent implements AfterViewInit {
  @Input()
  public title: string;

  @Input()
  public images: Image[];

  @Input()
  public closeNavigationRoute = '/';

  @Output()
  public closePage: EventEmitter<void> = new EventEmitter<void>();

  public showCarousel: boolean;
  public carouselOptions: {};
  public showSlider: boolean;
  public slideOptions = {
    speed: 400
  };

  constructor(private router: Router) {
    this.showCarousel = false;
    this.carouselOptions = {};
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.showSlider = true;
    }, 500);
  }

  public toggleCarousel(index?: number): void {
    this.showCarousel = !this.showCarousel;

    if (index) {
      this.carouselOptions = { initialSlide: index };
    } else {
      this.carouselOptions = {};
    }
  }

  public close(): void {
    if (this.closePage.observers.length) {
      this.closePage.emit();
    } else {
      this.router.navigate([this.closeNavigationRoute]);
    }
  }
}
