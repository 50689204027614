import { Component } from '@angular/core';
import { routeNames, lotStatuses } from '@config/index';
import { AuthService } from '@core/services/auth.service';
import { NavController } from '@ionic/angular';
import { KeyboardService } from '@core/services/keyboard.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabbar',
  templateUrl: 'tabbar.component.html',
  styleUrls: ['tabbar.component.scss']
})
export class TabbarComponent {
  public routeNames = routeNames;
  public lotStatuses = lotStatuses;

  constructor(
    public authService: AuthService,
    private navController: NavController,
    private keyboard: KeyboardService,
    private router: Router
  ) { }

  public routeTo(url: string): void {
    this.navController.navigateRoot(url, { animated: false });
  }

  public isActiveLink(url: string): boolean {
    return this.router.url.includes(url);
  }

  /**
   * Udpate dashboard when routing
   *
   * @returns void
   */
  public updateDashboard(): void {
    this.keyboard.dashboardToTop$.next();
  }

  /**
   * Route to Auth login
   *
   * @returns void
   */
  public routeToAuth(): void {
    this.navController.navigateBack(`/${routeNames.AUTH.DEFAULT}`, { animated: true, animationDirection: 'forward' });
  }
}
