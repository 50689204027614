import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalUtils } from '@shared/components/modal/modal.utils';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {
  constructor(private navParams: NavParams) {
  }

  public confirm(): void {
    ModalUtils.emitParam(this.navParams, 'confirm');
  }
}
