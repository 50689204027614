// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { BUILD_TIMESTAMP } from './timestamp';

export const environment = {
  production: false,
  name: 'Local',
  appUrl: 'https://scobiddo.stormcatch.nl',
  baseUrl: 'https://backoffice.veilingbalie.stormcatch.nl/v1',
  defaultUrl: 'https://backoffice.veilingbalie.stormcatch.nl',
  version: require('../../package.json').version + '.' + BUILD_TIMESTAMP,
  oneSignalIOS: '2604af93-0b50-4865-b917-bac7d0b80df7',
  oneSignalAndroid: '592141691789',
  bambuserIOS: 'g4NsiXNQLbN9kq22n2QHbw',
  bambuserAndroid: 'NG84SQbN0GLgyZi3Aqj3LA',
  bambuserWeb: 'Tel34nF9KGbpIA1jCVVnDg',
  bambuserAPIKey: '5LTGncUfFxmHzRDbPsaeqM',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
