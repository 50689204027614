import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BootHelper } from '@core/boot-helper/boot.helper';
import { RefreshTokenInterceptor } from '@core/interceptors/refresh-token.interceptor';
import { TokenAppendInterceptor } from '@core/interceptors/token-append.interceptor';
import { IonicStorageModule } from '@node_modules/@ionic/storage';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Facebook } from '@node_modules/@ionic-native/facebook/ngx';
import { GooglePlus } from '@node_modules/@ionic-native/google-plus/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { StorageService } from '@core/services/storage.service';

@NgModule({
  imports: [
    IonicStorageModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAppendInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    Keyboard,
    Clipboard,
    Facebook,
    GooglePlus,
    StorageService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    BootHelper.throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
