import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalUtils } from '@shared/components/modal/modal.utils';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent {
  constructor(private navParams: NavParams) {
  }

  public confirm(): void {
    ModalUtils.emitParam(this.navParams, 'confirm');
  }
}
